<template>
  <v-content>
    <router-view />
    <v-btn
      v-if="$router.currentRoute.name!=='contact'"
      fixed
      dark
      bottom
      right
      x-large
      elevation="10"
      ripple
      class="mb-10"
      color="primary"
      style="z-index: 1000"
      :to="'/' + $i18n.locale + '/contact'+(this.$route.params.company && this.$route.params.product?'?product='+this.$route.params.company+','+this.$route.params.product:'?quote=true')"
    >
      <v-icon class="mr-2">
        mdi-email
      </v-icon>
      {{ $t("contact.quote") }}
    </v-btn>
  </v-content>
</template>

<script>
  export default {
    metaInfo () {
      return {
        titleTemplate: '%s - Oria Agriculture',
      }
    },

    watch: {
      $route: function () {
        this.$nextTick(() => this.$vuetify.goTo(0))
      },
    },
  }
</script>
